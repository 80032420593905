<template>
	<client-only>
		<gmv-map
			ref="map"
			:style="{ height: height + 'px' }"
			:center="center"
			:zoom="zoom"
			:map-id="googleMapsId"
			:map-key="mapKey"
			:options="{
				streetViewControl: false
			}"
			@vue:mounted="fitToBounds"
		>
			<gmv-marker
				v-for="(location, index) in locations"
				:key="location.id"
				:ref="`marker-${index}`"
				:position="{ lat: location.latitude, lng: location.longitude }"
				:title="location.name"
			/>
		</gmv-map>
	</client-only>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { utilities } from '@gmap-vue/v3';
import { useRuntimeConfig } from 'nuxt/app';

export default defineComponent({
	name: 'HokMarkerMap',
	data() {
		const { googleMapsApiInitializer } = utilities;
		const {
			public: { googleMapsId }
		} = useRuntimeConfig();

		return {
			centerMapping: {
				at: { lat: 47.696528, lng: 13.345694 },
				de: { lat: 51.16338, lng: 10.44768 },
				ch: { lat: 46.801111, lng: 8.226667 }
			},
			googleMapsApiInitializer,
			googleMapsId
		};
	},
	mounted() {
		if (!window.google) {
			this.googleMapsApiInitializer(this.$gmapOptions.load);
		}
	},
	computed: {
		center() {
			if (this.initialCenter) {
				return this.initialCenter;
			}
			if (this.country && this.centerMapping[this.country]) {
				return this.centerMapping[this.country];
			}
			return { lat: 0, lng: 0 };
		},
		map() {
			return this.$refs.map as any;
		}
	},
	methods: {
		fitToBounds() {
			if (this.autoZoom) {
				this.map.mapPromise.then(map => {
					if (!window.google) {
						console.error('Google Maps API not ready');
						return;
					}

					const bounds = new window.google.maps.LatLngBounds();

					// Create bounds from locations
					this.locations?.forEach(location => {
						bounds.extend(
							new window.google.maps.LatLng({ lat: location.latitude, lng: location.longitude })
						);
					});
					/*
            					Don't zoom in too far on only one location
            					ref:  https://stackoverflow.com/questions/3334729/google-maps-v3-fitbounds-zoom-too-close-for-single-marker
            				*/
					if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
						const extendPoint1 = new window.google.maps.LatLng(
							bounds.getNorthEast().lat() + 0.001,
							bounds.getNorthEast().lng() + 0.001
						);
						const extendPoint2 = new window.google.maps.LatLng(
							bounds.getNorthEast().lat() - 0.001,
							bounds.getNorthEast().lng() - 0.001
						);
						bounds.extend(extendPoint1);
						bounds.extend(extendPoint2);
					}

					map.fitBounds(bounds);
				});
			}
		}
	},
	props: {
		mapKey: { type: String, default: 'gmap' },
		zoom: { type: Number, default: 6 },
		country: { type: String, validator: (value: string) => ['at', 'de', 'ch'].includes(value) },
		height: { type: Number, default: 300 },
		initialCenter: { type: Object },
		locations: {
			type: Array as PropType<{ id: string; name: string; latitude: number; longitude: number }[]>
		},
		autoZoom: { type: Boolean, default: true }
	}
});
</script>
