<template>
	<div
		class="flex justify-between hover:bg-color-main-light cursor-pointer"
		@click="$emit('company-clicked')"
	>
		<HokLink :to="`/c/${company.alias}`" class="text-color-grey p-3 w-full">
			<div class="flex flex-col items-start w-1/6 max-w-[72px]">
				<NuxtImg
					v-if="company.logos"
					:src="company.logos.small"
					:alt="company.name"
					:bg-cover="false"
					class="border border-color-grey-light object-contain bg-color-white h-12 w-12"
				/>
				<div
					v-else
					class="border border-color-grey-light bg-color-white flex justify-center items-center h-12 w-12"
				>
					<HokBgImg
						source="/shared-components-nuxt3/images/logo-placeholder.png"
						class="flex justify-center items-center h-10 w-10"
					>
						<p v-if="company.name" class="mb-0 uppercase text-3xl text-color-text">
							{{ company.name.charAt(0) }}
						</p>
					</HokBgImg>
				</div>
			</div>
			<div class="w-5/6">
				<h2
					v-if="company.name"
					class="font-bold text-sm sm:text-base text-color-text max-w-md leading-none mb-2"
					:class="nameContainsLongWord(company.name) ? 'break-all' : 'break-words'"
				>
					{{ shortenString(company.name.toString(), 75) }}
				</h2>
				<p
					v-if="company.location && company.location.length"
					class="flex text-color-text text-2xs sm:text-sm items-center mb-3"
				>
					<HokIcon :size="4" name="icon:pin-2" />
					{{ company.location[0].city }} &nbsp;
					<span v-if="company.location.length > 1" class="text-color-grey"
						>+{{ company.location.length - 1 }} weitere Standorte</span
					>
				</p>
				<div v-if="company.fields" class="flex mb-2">
					<div
						v-for="field in company.fields.slice(0, 2)"
						:key="field.name"
						class="flex items-center justify-center tracking-wide font-light bg-color-grey-lightest px-2 py-0 mr-2 rounded-full h-5"
						:class="[
							{ 'w-1/2': company.fields.length === 2 },
							{ 'w-2/5': company.fields.length > 2 }
						]"
					>
						<span class="truncate text-xs text-color-text text-center">{{ field.name }}</span>
					</div>
					<div
						v-show="company.fields.length > 2"
						class="flex items-center text-color-text bg-color-grey-lightest tracking-wide text-xs font-light px-4 py-0 text-sm rounded-full h-5"
					>
						...
					</div>
				</div>
				<span
					v-if="company.allowUnsolicitedApplications"
					class="text-xs text-color-white font-light bg-color-coral px-2 py-1 rounded-md mb-2 mr-2"
					>Initiativ bewerben</span
				>
			</div>
		</HokLink>
	</div>
</template>

<script lang="ts">
import type { IAPICompany } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { findLongestWord, shortenString } from '../helpers/stringhelper';

export default defineComponent({
	name: 'JobListCompanySlot',
	emits: ['company-clicked'],
	data() {
		return {
			shortenString
		};
	},
	methods: {
		nameContainsLongWord(name: string, limit = 35): boolean {
			const longestWord = findLongestWord(name);
			return longestWord.length > limit;
		}
	},
	props: {
		company: { type: Object as PropType<IAPICompany>, required: true }
	}
});
</script>
